export const dates = [
  ['за январь', '05.02.2025', '14.02.2025'],
  ['за февраль', '05.03.2025', '14.03.2025'],
  ['за март', '04.04.2025', '15.04.2025'],
  ['за апрель', '05.05.2025', '15.05.2025'],
  ['за май', '05.06.2025', '11.06.2025'],
  ['за июнь', '04.07.2025', '15.07.2025'],
  ['за июль', '05.08.2025', '15.08.2025'],
  ['за август', '05.09.2025', '15.09.2025'],
  ['за сентябрь', '03.10.2025', '15.10.2025'],
  ['за октябрь', '05.11.2025', '14.11.2025'],
  ['за ноябрь', '05.12.2025', '15.12.2025'],
  ['за декабрь', '30.12.2025', '15.01.2026'],
  ['за январь 2026', '05.02.2026', '13.02.2026'],
];
