import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {environment} from '@env/environment';
import {TuiDay, TuiMonth} from '@pik-taiga-ui/cdk';
import {TuiNotification, TuiNotificationsService} from '@pik-taiga-ui/core';
import {PikEnvironment, PikLocatorService} from 'pik-header';
import {
  combineLatest,
  from,
  map,
  merge,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {CURRENT_EMPLOYEE, EmployeeDto, PaymentSheetService} from 'src/app/home-api';

import {BLOCKED_REQUESTS} from '../../tokens';
import {dates} from '@app/employee/widgets/documents/data/dates';

@Component({
  selector: 'employee-documents',
  templateUrl: './documents.template.html',
  styleUrls: ['./documents.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDocumentsComponent {
  private readonly mothToDownload$ = new Subject<TuiMonth>();
  private readonly paymentRequest$ = this.mothToDownload$.pipe(
    tap(month => {
      this.lastSelectedMonth = month;
    }),
    switchMap(({month, year}) =>
      this.paymentSheetService.createRequest(year, month + 1).pipe(
        catchError((error: unknown) => {
          this.notificationsService
            .show('Не удалось сформировать расчётный лист', {
              label: `Произошла ошибка. ${
                error instanceof HttpErrorResponse ? error.status : ''
              }`,
              status: TuiNotification.Error,
            })
            .subscribe();

          return of({data: []});
        }),
        tap(res => {
          if (res && res.data) {
            this.notificationsService
              .show('Письмо будет отправлено на ваш email в течение 10 минут', {
                label: `Расчетный лист сформирован`,
                status: TuiNotification.Success,
              })
              .subscribe();
          }
        }),
        startWith(null),
      ),
    ),
    startWith([]),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  private readonly paymentDatesTest = new Map([[25050, 15]]);

  private readonly paymentDates = new Map([[4354, 15]]);

  private readonly holidays$ = this.getHolidaysAndWeekends().pipe(
    shareReplay({bufferSize: 1, refCount: true}),
  );

  private readonly paymentShedule$ = this.getPaymentShedule().pipe(
    shareReplay({bufferSize: 1, refCount: true}),
  );

  readonly isOutstaffer$ = this.currentEmployee$.pipe(
    map(({isOutstaffer}) => isOutstaffer),
  );

  readonly availablePeriod$ = combineLatest([
    this.currentEmployee$.pipe(
      map(employee => {
        return {
          paymentDay:
            (environment.pikEnvironment === PikEnvironment.test
              ? this.paymentDatesTest.get(employee.companyId)
              : this.paymentDates.get(employee.companyId)) || 5,
          startDay: TuiDay.fromLocalNativeDate(new Date(employee.employmentStartDate)),
        };
      }),
    ),

    this.paymentShedule$,
  ]).pipe(
    map(([{paymentDay, startDay}, paymentShedule]) => {
      let today = TuiDay.fromLocalNativeDate(new Date());

      if (paymentShedule.testDate) {
        const testDate = paymentShedule.testDate.split('.').reverse().join('-');
        today = TuiDay.fromUtcNativeDate(new Date(testDate));
      }

      if (paymentShedule.testSend) {
        paymentDay = paymentShedule.testSend;
      }

      const getFirstPaymentDate = (calcDate: TuiDay): TuiDay => {
        const dates = paymentShedule[paymentDay] || paymentShedule[5];
        let monthIndex = calcDate.month ? calcDate.month - 1 : 0;

        if (calcDate.year > new Date().getFullYear()) {
          monthIndex = 11;
        }

        const date = dates[monthIndex]?.split('.');

        if (date && Array.isArray(date)) {
          const sheduleDate = TuiDay.fromUtcNativeDate(
            new Date(date.reverse().join('-')),
          );

          return new TuiDay(calcDate.year, sheduleDate.month, sheduleDate.day);
        }
      };

      const getPaymentDate = (calcDate: TuiDay): TuiDay => {
        const dates = paymentShedule[paymentDay] || paymentShedule[5];
        let monthIndex = calcDate.month ? calcDate.month - 1 : 0;

        if (calcDate.year > new Date().getFullYear()) {
          monthIndex = 11;
        }

        const date = dates[monthIndex]?.split('.');

        if (date && Array.isArray(date)) {
          const sheduleDate = TuiDay.fromUtcNativeDate(
            new Date(date.reverse().join('-')),
          );

          return new TuiDay(today.year, sheduleDate.month, sheduleDate.day);
        }
      };

      const getDates = (today: TuiDay, startDay: TuiDay) => {
        const start = startDay.append({month: 0});
        const calcFirstPaymentDate = getFirstPaymentDate(start);
        const firstPaymentDate = new TuiDay(
          calcFirstPaymentDate.year,
          calcFirstPaymentDate.month,
          calcFirstPaymentDate.day,
        );

        const calcNextDate = today.append({month: 1});
        const calcPervDate = today.append({month: -1});

        const pervCalcDateAvailable = calcPervDate;

        const nextCalcDateAvailable = getPaymentDate(calcNextDate);

        return {
          firstPaymentDate,
          pervCalcDateAvailable,
          nextCalcDateAvailable,
          start,
        };
      };

      let dates = getDates(today, startDay);

      const currentPaymentDate = getPaymentDate(today);

      // Если РЛ за прошлый месяц еще не готов (1,2,3,4,5 числа), доступен РЛ только позапрошлого месяца

      if (currentPaymentDate > today) {
        today = today.append({month: -1});

        dates = getDates(today, startDay);
      }
      // Если РЛ за текущий месяц уже готов (30.12, 31.12), показываем его доступность и сообщение о готовности РЛ будущего месяца

      if (dates.nextCalcDateAvailable.daySameOrBefore(today)) {
        today = today.append({month: 1});

        dates = getDates(today, startDay);
      }

      const {firstPaymentDate, pervCalcDateAvailable, nextCalcDateAvailable, start} =
        dates;

      return {
        disabled: today.monthSame(startDay),

        firstPaymentDate: {
          obj: firstPaymentDate,
          month: firstPaymentDate,
          monthTitle: firstPaymentDate
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {month: 'long'}),
          dateTitle: firstPaymentDate
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'}),
        },
        lastPaymentDate: {
          obj: pervCalcDateAvailable,
          month: pervCalcDateAvailable,
          monthTitle: pervCalcDateAvailable

            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {month: 'long'}),
          dateTitle: pervCalcDateAvailable

            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'}),
        },
        nextPaymentDate: {
          obj: nextCalcDateAvailable,
          month: nextCalcDateAvailable,
          monthTitle: nextCalcDateAvailable

            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {month: 'long'}),
          dateTitle: nextCalcDateAvailable
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'}),
        },
        nextCalcDateAvailable: {
          obj: nextCalcDateAvailable,
          month: nextCalcDateAvailable,
          monthTitle: nextCalcDateAvailable
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {month: 'long'}),
          dateTitle: nextCalcDateAvailable
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'}),
        },
        currentPaymentMonth: {
          obj: today,
          month: today,
          monthTitle: today

            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {month: 'long'}),
          dateTitle: today
            .toLocalNativeDate()
            .toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'}),
        },
      };
    }),

    catchError((_err: unknown) => {
      this.notificationsService
        .show('Не удалось получить доступные данные для Расчетного листа', {
          label: `Расчетный лист временно недоступен`,
          status: TuiNotification.Error,
        })
        .subscribe();

      return of({disabled: true});
    }),
    shareReplay({bufferSize: 1, refCount: true}),
    startWith(null),
  );

  lastSelectedMonth: TuiMonth;

  readonly paymentSheetLoading$ = merge(
    this.paymentRequest$.pipe(map(lettersList => lettersList === null)),
    this.availablePeriod$.pipe(map(period => period === null)),
  );

  readonly serviceDeskURL = this.pikLocator.urls.serviceDeskUrl;

  readonly blockedRequests$ = this.blockedInstances$;
  readonly paysheetAvailable$ = combineLatest([
    this.blockedRequests$,
    this.isOutstaffer$,
  ]).pipe(
    map(([blockedRequests, isOutstaffer]) => {
      return !isOutstaffer && blockedRequests.paysheet !== true;
    }),
  );

  calendarOpen = false;

  constructor(
    private readonly pikLocator: PikLocatorService,
    @Inject(CURRENT_EMPLOYEE) private readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(PaymentSheetService)
    private readonly paymentSheetService: PaymentSheetService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(TuiNotificationsService)
    private readonly notificationsService: TuiNotificationsService,
    @Inject(BLOCKED_REQUESTS)
    private readonly blockedInstances$: Observable<{
      [sdRequestCode: string]: true | undefined;
    }>,
  ) {}

  startDownload(month: TuiMonth) {
    this.mothToDownload$.next(month);
  }

  toggleCalendar(event: MouseEvent) {
    event.stopPropagation();
    this.calendarOpen = true;
  }

  /**
   * Сервис для получения производственного календаря
   * https://docs.google.com/spreadsheets/d/11jf-TDKcbemqL98msnyb5ipSVP6-aK8-Iimyy8ulV4M/edit#gid=0
   */
  private getHolidaysAndWeekends(): Observable<{[year: number]: string[]}> {
    // const gapiUrl = 'https://apis.google.com/js/api.js';

    return of([
      [
        '2024',
        ['1,2,3,4,5,6,7,8'],
        ['23'],
        ['8'],
        null,
        ['1,9'],
        ['12'],
        null,
        null,
        null,
        null,
        ['4'],
        null,
      ],
    ]).pipe(
      // switchMap(_ =>
      //   from(
      //     new Promise<void>(resolve => {
      //       gapi.load('client', () => {
      //         resolve(
      //           gapi.client.init({
      //             apiKey: 'AIzaSyBlCo79Wnk5G4B7QTVWCwK1VL25cvU_s2w',
      //             discoveryDocs: [
      //               'https://sheets.googleapis.com/$discovery/rest?version=v4',
      //             ],
      //           }),
      //         );
      //       });
      //     }),
      //   ),
      // ),
      // switchMap(async _ =>
      //   gapi.client.sheets.spreadsheets.values.get({
      //     spreadsheetId: '11jf-TDKcbemqL98msnyb5ipSVP6-aK8-Iimyy8ulV4M',
      //     range: 'Sheet1!A2:M9',
      //   }),
      // ),
      map((val: string[][]) => {
        /**
         * Массив массивов из 13 элементов, [год, ...12 месяцев].
         * Каждый месяц это строка где через запятую даты, которые являются праздничными или выходными
         */
        const lines: string[][] = val;

        const yearsToMonthes: {[year: number]: string[]} = {};

        lines.forEach(([year, ...monthes]) => {
          yearsToMonthes[parseInt(year, 10)] = monthes;
        });

        return yearsToMonthes;
      }),
    );
  }

  private getPaymentShedule(): Observable<any> {
    // const gapiUrl = 'https://apis.google.com/js/api.js';

    return of([
      [
        '2025',
        ['1,2,3,4,5,6,7,8'],
        null,
        null,
        null,
        ['1,2,8,9'],
        ['12,13'],
        null,
        null,
        null,
        null,
        ['3,4'],
        null,
      ],
    ]).pipe(
      // switchMap(_ =>
      //   from(
      //     new Promise<void>(resolve => {
      //       gapi.load('client', () => {
      //         resolve(
      //           gapi.client.init({
      //             apiKey: 'AIzaSyBlCo79Wnk5G4B7QTVWCwK1VL25cvU_s2w',
      //             discoveryDocs: [
      //               'https://sheets.googleapis.com/$discovery/rest?version=v4',
      //             ],
      //           }),
      //         );
      //       });
      //     }),
      //   ),
      // ),
      // switchMap(async _ =>
      //   gapi.client.sheets.spreadsheets.values.get({
      //     spreadsheetId: '15po7x3cgK-LzqLUbxEyUsOZQ_fXDkPq1H8H-mU4Wv4A',
      //     range: 'Лист1!A2:C14',
      //   }),
      // ),
      map(result => {
        let today = TuiDay.fromLocalNativeDate(new Date());
        const shedule = {5: {}, 15: {}, testDate: null, testSend: null};

        if (!result) {
          return shedule;
        }

        if (result.length >= 15) {
          const testSend = result.pop();

          shedule.testSend = testSend[2] || null;
        }

        if (result.values.length >= 14) {
          const testDate = result.pop();
          shedule.testDate = testDate[2] || null;
        }

        if (today.year === 2025) {
          dates.forEach(([monthName, firstDate, secondDate], ndx) => {
            shedule[5][ndx] = firstDate;
            shedule[15][ndx] = secondDate;
          });
        } else {
          result?.forEach(([monthName, firstDate, secondDate], ndx) => {
            shedule[5][ndx] = firstDate;
            shedule[15][ndx] = secondDate;
          });
        }

        return shedule;
      }),
    );
  }
}
